<template>
    <section>
        <div class="container">
            <TitleBar title="Numune Kayıtları" :modi="modi" />

            <b-field class="columns box" label="Filtre Alanları" grouped group-multiline>
                <b-field class="column is-2" label="ID">
                    <b-input v-model="samples.filters.id"></b-input>
                </b-field>
                <b-field class="column is-4" label="Numune Açıklaması">
                    <b-input v-model="samples.filters.name"></b-input>
                </b-field>
                <b-field class="column is-3" label="Müşteri/Tedarikçi Unvan">
                    <CustomerAutofill v-model="samples.filters.customer" />
                </b-field>
                <b-field class="column is-3" label="Durum">
                    <b-select placeholder="Seçiniz.." v-model="samples.filters.status" expanded>
                        <option value="">Hepsi</option>
                        <option value="Çalışma Bekliyor">Çalışma Bekliyor</option>
                        <option value="Reçete Çalışmasında">Reçete Çalışmasında</option>
                        <option value="Malzeme Kaydı Bekliyor">Malzeme Kaydı Bekliyor</option>
                        <option value="Fiyat Bekliyor">Fiyat Bekliyor</option>
                        <option value="Sevk Aşamasında">Sevk Aşamasında</option>
                        <option value="Sevk Edildi">Sevk Edildi</option>
                        <option value="Askıda">Askıda</option>
                        <option value="İptal">İptal</option>
                    </b-select>
                </b-field>
                <b-field class="column is-3" label="Sektörel Özellik">
                    <b-input v-model="samples.filters.sector"></b-input>
                </b-field>
                <b-field class="column is-3" label="Renk">
                    <b-input v-model="samples.filters.color"></b-input>
                </b-field>
                <b-field class="column is-3" label="Giriş Tarihi">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="samples.filters.indate" range> </b-datepicker>
                </b-field>
                <b-field class="column is-3" label="Çıkış Tarihi">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="samples.filters.outdate" range> </b-datepicker>
                </b-field>
            </b-field>

            <b-field class="columns box mt-2" label="Numune Tablosu" grouped group-multiline>
                <b-field class="column is-12 my-1" grouped group-multiline>
                    <b-button class="column" type="is-info is-light" icon-left="magnify" label="Ara" @click="samples_read" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="plus" label="Yeni" @click="samples_new" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="content-copy" label="Ref. Al" @click="samples_ref" disabled />
                    <b-button class="column ml-1" type="is-info is-light" icon-left="eye" label="Görüntüle" :disabled="!samples.selected.id" @click="samples_show" />
                    <b-button class="column ml-1" type="is-warning is-light" icon-left="pencil" label="Düzenle" :disabled="!samples.selected.id" @click="samples_edit" />
                    <b-button class="column ml-1" type="is-danger is-light" icon-left="delete" label="Sil" :disabled="!samples.selected.id" @click="samples_delete" />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="printer" label="Yazdır" @click="samples_print" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Içe Aktar" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" @click="samples_export" disabled />
                </b-field>

                <b-table
                    class="column is-12"
                    :mobile-cards="false"
                    :data="samples.data"
                    :total="samples.total"
                    :current-page.sync="samples.page"
                    :selected.sync="samples.selected"
                    :sort-multiple-data.sync="samples.sorts"
                    :loading="$apollo.queries.readSamples.loading"
                    @dblclick="samples_show"
                    @sort="samples_sort"
                    @sorting-priority-removed="samples_nosort"
                    backend-pagination
                    backend-sorting
                    sort-multiple
                    striped
                    hoverable
                    narrowed
                    paginated
                    pagination-simple
                >
                    <template slot="empty">
                        <div class="is-12 has-text-centered">
                            Numune kaydı bulunamadı.
                        </div>
                    </template>

                    <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-4) }}</b-table-column>
                    <b-table-column field="name" label="Numune Açıklaması" v-slot="props" sortable>{{ props.row.name }}</b-table-column>
                    <b-table-column field="customer.name" label="Müşteri Firma" v-slot="props">{{ props.row.customer.name }}</b-table-column>
                    <b-table-column field="color" label="Renk" v-slot="props" sortable>{{ props.row.color }}</b-table-column>
                    <b-table-column field="sector" label="Sektörel Özellik" v-slot="props" sortable>{{ props.row.sector }}</b-table-column>
                    <b-table-column field="quantity" label="Miktar (KG)" v-slot="props" sortable>{{ props.row.quantity }} KG</b-table-column>
                    <b-table-column field="indate" label="Giriş Tarihi" v-slot="props" sortable>{{
                        new Date(props.row.indate).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric"
                        })
                    }}</b-table-column>
                    <b-table-column field="outdate" label="Çıkış Tarihi" v-slot="props" sortable>{{
                        new Date(props.row.outdate).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric"
                        })
                    }}</b-table-column>
                    <b-table-column field="status" label="Durum" v-slot="props" sortable>
                        <b-tag :type="statusType(props.row.status)">
                            {{ props.row.status }}
                        </b-tag></b-table-column
                    >
                </b-table>
            </b-field>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import CustomerAutofill from "@/components/CustomerAutofill.vue";

export default {
    name: "SampleTable",
    components: {
        TitleBar,
        CustomerAutofill
    },
    data: () => ({
        modi: "",
        samples: {
            page: 1,
            take: 20,
            total: 9999,
            data: [],
            sorts: [],
            filters: {
                customer: {},
                indate: [],
                outdate: []
            },
            selected: {}
        }
    }),
    computed: {
        statusType() {
            return (status) =>
                status == "Çalışma Bekliyor"
                    ? "is-danger is-light"
                    : status == "Reçete Çalışmasında"
                    ? "is-info is-light"
                    : status == "Malzeme Kaydı Bekliyor"
                    ? "is-dark is-light"
                    : status == "Fiyat Bekliyor"
                    ? "is-white is-light"
                    : status == "Sevk Aşamasında"
                    ? "is-primary is-light"
                    : status == "Sevk Edildi"
                    ? "is-success is-light"
                    : status == "Askıda"
                    ? "is-warning light"
                    : "is-light";
        }
    },
    mounted() {
        this.samples_read();
    },
    methods: {
        samples_read() {
            this.$apollo.queries.readSamples.start();
            this.$apollo.queries.readSamples.refetch();
        },
        samples_new() {
            this.$router.push({ path: "/lab/numune/ekle" });
        },
        samples_ref() {
            this.$router.push({
                path: "/lab/numune/ekle",
                query: { id: this.samples.selected.id, modi: "ref" }
            });
        },
        samples_show() {
            this.$router.push({
                path: "/lab/numune/detay",
                query: { id: this.samples.selected.id, modi: "show" }
            });
        },
        samples_edit() {
            this.$router.push({
                path: "/lab/numune/detay",
                query: { id: this.samples.selected.id, modi: "edit" }
            });
        },
        samples_delete() {},
        samples_print() {},
        samples_export() {},
        samples_sort(field, order) {
            this.samples_nosort(field);
            this.samples.sorts.push({ field, order });
        },
        samples_nosort(field) {
            this.samples.sorts.forEach((e, i, a) => {
                if (e.field == field) a.splice(i, 1);
            });
        }
    },
    apollo: {
        readSamples: {
            query: gql`
                query readSamples($config: String) {
                    readSamples(config: $config) {
                        id
                        name
                        customer {
                            id
                            name
                            firmrep
                        }
                        color
                        sector
                        quantity
                        indate
                        outdate
                        status
                    }
                }
            `,
            variables() {
                var sorts = [];
                this.samples.sorts.forEach((e) => sorts.push({ column: e.field, order: e.order }));
                for (var filter in this.samples.filters) if (!this.samples.filters[filter]) delete this.samples.filters[filter];

                return {
                    config: JSON.stringify({
                        sorts,
                        take: this.samples.take,
                        filters: this.samples.filters,
                        skip: (this.samples.page - 1) * this.samples.take
                    })
                };
            },
            update(data) {
                this.samples.data = data.readSamples;
            }
        }
    }
};
</script>
